import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// ----------------------------------------------------------------------
Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M1335 2326 c-154 -36 -272 -101 -386 -215 -160 -161 -231 -330 -227
-545 l1 -91 7 115 c5 72 16 141 30 185 99 315 371 526 696 542 225 11 428 -64
578 -213 154 -154 228 -328 230 -540 1 -62 4 -107 8 -100 13 20 9 174 -7 250
-59 287 -281 525 -560 601 -83 23 -292 29 -370 11z"/>
<path d="M1177 1921 c48 -8 58 -15 48 -31 -5 -8 -11 -8 -21 1 -17 14 -16 14
-38 -28 -15 -30 -15 -36 -1 -63 8 -16 24 -34 35 -40 23 -13 24 -19 5 -44 -13
-17 -14 -17 -36 3 -39 36 -52 20 -24 -29 l15 -25 -6 30 -7 30 21 -19 c16 -14
19 -23 12 -31 -6 -7 -14 -37 -17 -66 -6 -54 -14 -51 -27 14 -7 31 -22 35 -31
8 -3 -12 5 -35 24 -62 16 -24 38 -65 50 -90 12 -25 35 -60 52 -77 27 -28 30
-36 27 -84 l-3 -53 -20 33 c-25 40 -53 49 -94 28 -17 -9 -31 -19 -31 -21 0
-13 66 -36 91 -31 37 7 56 -15 41 -49 -8 -17 -22 -26 -49 -30 -70 -12 -59 -13
107 -13 121 0 153 2 121 9 -59 11 -65 19 -45 62 17 36 37 47 87 47 17 0 27 5
27 15 0 19 -7 19 -59 0 l-41 -14 6 27 c10 40 22 60 44 72 11 6 20 15 20 20 0
16 -38 11 -48 -6 -15 -27 -32 57 -18 85 11 20 19 14 144 -113 144 -146 155
-167 100 -191 -30 -13 -17 -14 147 -14 176 0 179 1 131 15 -72 23 -99 44 -290
232 l-173 170 136 135 c151 149 181 173 237 186 29 7 4 9 -101 9 -77 0 -124
-3 -105 -7 73 -13 39 -81 -120 -235 -99 -97 -100 -97 -125 -80 -21 14 -25 24
-25 65 0 37 4 49 15 49 17 0 19 12 5 26 -7 7 -6 20 5 40 19 36 19 44 1 44 -20
0 -29 35 -16 60 10 19 23 24 80 33 14 3 -49 4 -140 4 -94 0 -147 -2 -123 -6z
m93 -35 c0 -20 -69 -78 -82 -70 -7 4 -8 2 -4 -5 4 -6 13 -8 19 -5 7 4 9 4 5
-1 -4 -4 0 -14 8 -22 26 -26 8 -25 -20 0 -14 14 -24 31 -22 38 2 8 7 27 10 42
4 16 11 25 17 21 6 -3 9 -15 6 -25 -3 -10 -1 -22 4 -25 5 -3 9 4 9 15 0 20 24
51 41 51 5 0 9 -6 9 -14z m30 -11 c0 -8 5 -15 10 -15 11 0 13 -40 4 -65 -4
-10 -21 -23 -38 -30 -31 -14 -31 -14 23 -13 64 0 84 -14 43 -31 -25 -10 -35
-10 -60 3 l-31 16 2 -169 c2 -142 0 -167 -12 -158 -23 19 -29 50 -17 92 6 22
10 51 9 65 -2 14 -4 30 -5 37 -3 29 -14 0 -16 -44 -2 -26 -5 -57 -9 -68 -10
-33 -34 50 -27 93 9 54 22 75 45 69 13 -3 19 0 19 10 0 10 -6 14 -19 10 -19
-5 -19 -3 -3 29 8 19 19 34 22 34 9 0 6 29 -5 54 -6 15 -1 25 21 46 17 14 28
32 26 38 -2 7 1 12 7 12 6 0 11 -7 11 -15z m64 -58 c14 -22 -27 -68 -45 -50
-7 7 -3 16 17 32 19 15 22 21 10 21 -9 0 -16 5 -16 11 0 15 21 6 34 -14z m-49
-152 c0 -11 -8 -21 -17 -23 -14 -3 -18 3 -18 23 0 20 4 26 18 23 9 -2 17 -12
17 -23z m13 -99 c-2 -19 2 -38 9 -45 17 -15 17 -34 1 -29 -17 6 -35 43 -44 92
-6 37 -6 38 16 26 17 -9 21 -19 18 -44z m45 26 c17 -18 22 -41 18 -77 -2 -20
-2 -20 -11 0 -7 17 -9 16 -10 -10 l-2 -30 -13 35 c-15 40 -19 100 -7 100 5 0
16 -8 25 -18z m-51 -100 l23 -25 -32 17 c-33 17 -51 55 -37 78 5 8 10 3 15
-16 5 -16 18 -40 31 -54z m294 -94 c95 -95 174 -176 174 -180 0 -5 -19 -8 -43
-8 -40 0 -44 2 -56 35 -8 22 -59 82 -134 158 -66 67 -120 125 -119 130 1 4 -2
7 -8 7 -5 0 -7 7 -4 15 4 8 8 15 11 15 3 0 83 -78 179 -172z m-241 40 c11 -58
12 -88 4 -88 -4 0 -9 6 -12 13 -2 6 -8 9 -12 4 -5 -4 -3 -13 3 -19 14 -14 16
-77 3 -96 -6 -10 -10 -8 -14 10 -9 31 -9 193 0 201 13 14 22 6 28 -25z m-75
11 c0 -5 5 -7 10 -4 6 4 10 -13 11 -42 1 -76 6 -146 10 -168 3 -16 -1 -20 -26
-20 l-30 0 2 95 c2 52 3 107 3 123 0 15 5 27 10 27 6 0 10 -5 10 -11z"/>
<path d="M1120 1464 c-27 -12 -32 -17 -20 -24 18 -12 77 -13 84 -2 2 4 -14 6
-37 4 l-42 -3 35 20 c41 24 30 27 -20 5z"/>
<path d="M875 1130 c-10 -11 -27 -20 -38 -20 -16 0 -17 -2 -7 -15 10 -12 16
-13 26 -4 16 13 19 -2 4 -17 -7 -7 -5 -17 5 -30 14 -18 14 -17 15 15 0 31 2
33 29 29 19 -3 27 0 24 7 -2 7 -16 10 -31 8 -26 -4 -26 -4 -8 11 14 11 17 19
10 26 -7 7 -16 4 -29 -10z"/>
<path d="M2080 1117 c0 -9 5 -19 11 -23 6 -3 17 -20 24 -36 9 -22 14 -26 18
-15 3 8 -4 25 -15 37 l-21 22 24 -7 c13 -4 26 -11 29 -16 3 -4 11 0 17 10 10
16 8 19 -20 24 -18 3 -40 8 -49 12 -13 5 -18 2 -18 -8z"/>
<path d="M2006 1028 c-19 -22 -24 -34 -16 -42 7 -7 10 -4 10 10 1 16 5 14 29
-13 21 -22 32 -28 40 -20 13 13 15 31 2 23 -4 -3 -18 5 -30 18 -21 22 -21 25
-6 34 10 5 13 12 8 15 -5 3 -22 -8 -37 -25z"/>
<path d="M940 1007 c-34 -15 -33 -14 -25 -27 4 -7 10 -8 14 -4 3 5 16 14 27
21 20 11 21 10 10 -12 -6 -13 -14 -21 -18 -19 -12 8 -9 -11 4 -24 9 -9 16 -2
30 29 10 23 18 43 18 45 0 8 -35 3 -60 -9z"/>
<path d="M1050 937 c0 -21 -6 -39 -15 -44 -12 -7 -11 -11 7 -22 18 -11 20 -11
15 3 -3 8 -2 18 2 21 16 9 19 55 5 66 -11 9 -14 5 -14 -24z"/>
<path d="M1916 918 c4 -18 8 -40 8 -49 0 -9 6 -15 13 -12 7 3 11 13 10 23 -1
10 3 19 8 18 6 0 9 -4 7 -9 -1 -6 8 -4 20 2 18 10 19 13 8 17 -8 3 -26 13 -39
23 -35 27 -44 24 -35 -13z"/>
<path d="M1145 890 c-4 -6 -4 -25 0 -43 5 -29 5 -30 -9 -14 -8 10 -17 14 -20
9 -9 -14 21 -35 40 -28 12 5 15 14 10 36 -5 29 7 42 17 18 4 -8 6 -7 6 3 1 9
-6 20 -15 23 -21 8 -22 8 -29 -4z"/>
<path d="M1793 878 c-21 -10 -21 -11 -3 -41 11 -17 16 -35 13 -40 -7 -11 37 1
55 15 15 13 16 50 0 66 -14 14 -35 14 -65 0z m52 -37 c0 -34 -19 -37 -33 -6
-16 35 -15 37 11 33 16 -2 22 -10 22 -27z"/>
<path d="M1230 850 c0 -5 7 -7 15 -4 19 8 19 2 1 -41 -8 -19 -13 -35 -11 -35
2 0 11 -3 21 -7 11 -4 15 -3 11 4 -11 18 11 66 28 59 8 -3 17 -1 19 3 3 4 -12
13 -33 20 -47 13 -51 14 -51 1z"/>
<path d="M1616 823 c-13 -3 -15 -7 -7 -12 6 -4 12 -23 13 -42 1 -33 3 -35 30
-31 18 3 28 10 28 21 0 13 -2 13 -9 2 -5 -7 -14 -10 -20 -7 -17 11 -13 26 6
26 9 0 13 3 9 8 -4 4 -12 7 -17 7 -5 0 -8 5 -6 10 1 6 8 9 15 9 6 -1 12 2 12
7 0 9 -22 10 -54 2z"/>
<path d="M1352 808 c-29 -29 7 -86 47 -73 26 8 38 50 21 70 -15 18 -51 20 -68
3z m56 -26 c2 -13 -3 -25 -13 -28 -23 -9 -38 10 -30 36 9 27 39 22 43 -8z"/>
<path d="M1485 770 c0 -25 5 -40 13 -40 6 0 12 8 12 17 0 14 3 13 21 -5 11
-12 25 -20 30 -16 6 3 2 12 -10 21 -16 10 -19 21 -15 39 5 21 3 24 -22 24 -27
0 -29 -3 -29 -40z"/>
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
